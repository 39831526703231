<template>
    <div>

        <!-- monitoring TODO delete -->
        <p v-if=""><b>:</b> {{mapOptions}}</p>
        <hr>
        <!-- end monitoring TODO delete -->

        <h2 align="center" class="mb-2">Destinations-test</h2>
        <!-- toolbar -->
        <b-button-toolbar class="m-2">
            <!--            <b-button @click="test()">Test</b-button>-->
            <b-button class="ml-1" @click="init()">init</b-button>
            <b-button class="ml-1" @click="removeMap()">Remove map</b-button>
            <!--            <b-button class="ml-1" @click="sitePoint()">sitePoint</b-button>-->
            <!--            <b-button class="ml-1" @click="english()">English</b-button>-->
            <b-button class="ml-1" @click="coordinates()">Coordinates</b-button>
            <b-button class="ml-1" @click="layers()">Layers</b-button>

            <b-button class="ml-1" @click="addMarkers()">AddMarkers</b-button>
            <b-button class="ml-1" @click="removeMarker()">RemoveMarker</b-button>
        </b-button-toolbar>

        <b-button-toolbar class="mx-2">
            <b-form-checkbox
                id="active"
                v-model="scrollWheelZoom"
                class="float-right"
                name="active"
                switch
            >
                scrollWheelZoom
            </b-form-checkbox>
        </b-button-toolbar>

        <!-- leaflet map -->
        <div id="map" class="mt-2 border" style="width: 700px; height: 480px"></div>

    </div>
</template>

<script>
import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

// @group DEVELOPER
export default {
    name: "Leaflet_js",
    components: {},
    props: {},
    data() {
        return {
            map: null,
            mapOptions: {
                center: [46.51834473343716, 16.196244025819553],
                zoom: 12,
                scrollWheelZoom: false,
                gestureHandling: true,
            },
            mapLayers: {
                'Street view': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                    // maxZoom: 20,
                    // minZoom: 2,
                    subdomains: ['a', 'b', 'c'],
                    layers: 'openstreetmap_org'
                }),
                'Google streets': L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    layers: 'googleStreets'
                }),

                'Google hybrid': L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    layers: 'googleHybrid'
                }),
                'Google satelite': L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    layers: 'googleSatelite'
                }),
                'Google terrain': L.tileLayer('https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    layers: 'googleSatelite'
                }),
                'Google traffic': L.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    minZoom: 2,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    layers: 'googleTraffic'
                }),
                'Street view (eng)': L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png', {
                    minZoom: 3,
                    maxZoom: 17,
                    attribution: '&copy; <a href="https://carto.com/">carto.com</a> contributors',
                    layers: 'openstreetmap_org'
                }),
                'Street view (de)': L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/ {y}.png', {
                    minZoom: 3,
                    maxZoom: 17,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                    layers: 'openstreetmap_org'
                }),
                'Street view (fr)': L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
                    minZoom: 3,
                    maxZoom: 17,
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                    layers: 'openstreetmap_org'
                }),
                'Osm.org': L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    minZoom: 3,
                    maxZoom: 17,
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    layers: 'osm_org'
                }),
                Topography: L.tileLayer.wms('http://ows.mundialis.de/services/service?', {layers: 'TOPO-WMS'}),
                Places: L.tileLayer.wms('http://ows.mundialis.de/services/service?', {layers: 'OSM-Overlay-WMS'}),
            },
            markers: [],
        }
    },
    mounted() {
        // this.init();
    },
    methods: {
        test() {
            // Creating map options
            let mapOptions = {
                center: [46.51834473343716, 16.196244025819553],
                zoom: 12
            }

            // Creating a map object
            let map = new L.map('map', mapOptions);
            // Creating a Layer object
            let layer = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
            // let layer1 = new L.TileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/ {y}.png');
            // let layer1 = new L.TileLayer('https://{s}.tile.openstreetmap.en/tiles/osmen/{z}/{x}/ {y}.png');

            // map.setLanguage('sl', true);

            // Adding layer to the map
            map.addLayer(layer);

            // map.eachLayer(layerA => {
            //     console.log("layerA", layerA._map.options.center);
            //     // map.remove(layerA);
            // });

            // <-- contenxmenu: add cordinates -->
            // map.on("contextmenu", function (event) {
            //     console.log("Coordinates: " + event.latlng.toString());
            //     L.marker(event.latlng).addTo(map);
            // });
            // map.remove(layer);

        },
        // <--  -->
        init() {
            // this.map = L.map('map', {scrollWheelZoom: false})
            //     .setView([-29.50, 145], 3.5);

            L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
            // Creating a map object
            this.map = new L.map('map', this.mapOptions);

            L.control.layers(this.mapLayers).addTo(this.map);
            this.mapLayers['Street view'].addTo(this.map);
        },
        removeMap() {
            this.map.eachLayer(layer => {
                this.map.remove(layer);
            });
        },
        sitePoint() {
            const map = L.map('map', {scrollWheelZoom: false}).setView([-29.50, 145], 3.5);

            const basemaps = {
                StreetView: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}),
                Topography: L.tileLayer.wms('http://ows.mundialis.de/services/service?', {layers: 'TOPO-WMS'}),
                Places: L.tileLayer.wms('http://ows.mundialis.de/services/service?', {layers: 'OSM-Overlay-WMS'})
            };

            L.control.layers(basemaps).addTo(map);

            basemaps.Topography.addTo(map);

            const basicBeachIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/shacheeswadia/leaflet-map/main/beach-icon-chair.svg',
                iconSize: [40, 40],
            });

            const marker1 = L.marker([-37.699450, 176.279420], {icon: basicBeachIcon}).addTo(map);
            const marker2 = L.marker([-27.643310, 153.305140], {icon: basicBeachIcon}).addTo(map);
            const marker3 = L.marker([-33.956330, 122.150270], {icon: basicBeachIcon}).addTo(map);
            const marker4 = L.marker([-34.962390, 117.391220], {icon: basicBeachIcon}).addTo(map);
            const marker5 = L.marker([-17.961210, 122.214820], {icon: basicBeachIcon}).addTo(map);
            const marker6 = L.marker([-16.505960, 151.751520], {icon: basicBeachIcon}).addTo(map);
            const marker7 = L.marker([-22.594400, 167.484440], {icon: basicBeachIcon}).addTo(map);
            const marker8 = L.marker([-37.977000, 177.057000], {icon: basicBeachIcon}).addTo(map);
            const marker9 = L.marker([-41.037600, 173.017000], {icon: basicBeachIcon}).addTo(map);
            const marker10 = L.marker([-37.670300, 176.212000], {icon: basicBeachIcon}).addTo(map);
        },
        english() {
            let mapOptions = {
                center: [17.385044, 78.486671],
                zoom: 10
            }

            // Creating a map object
            let map = new L.map('map', mapOptions);

            const mainLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png', {
                minZoom: 3,
                maxZoom: 17,
                attribution: '&copy; <a href="https://carto.com/">carto.com</a> contributors'
            });
            mainLayer.addTo(map);
        },
        coordinates() {
            console.log("coordinates", this.map.getBounds()); // TODO delete
        },

        layers() {
            this.map.eachLayer(layer => {
                console.log("layer", layer); // TODO delete
                // this.map.remove(layer);
            });
        },

        addMarkers() {
            let marker = L.marker([46.51834473343716, 16.196244025819553]);
            marker.id = 219;
            marker.bindPopup("This is the Transamerica Pyramid");
            // marker.addTo(this.map);


            let markerGroup = L.layerGroup().addTo(this.map);
            //
            marker.addTo(markerGroup);


            marker = L.marker([46.51834475343816, 17.196244025819253]);
            marker.id = 220;
            marker.addTo(markerGroup);

            // <-- remove layer -->
            let id = markerGroup.getLayers()[0]._leaflet_id;
            console.log("layers", markerGroup.getLayers()[0]._leaflet_id); // TODO delete
            // markerGroup.removeLayer(id);

            // <-- remove group layers -->
            // markerGroup.clearLayers();
        },
        removeMarker() {
            console.clear(); // TODO delete
            this.map.eachLayer(layer => {
                console.log("layer", layer); // TODO delete
                if (layer.id === 219) {
                    console.log("remove"); // TODO delete
                    this.map.removeLayer(layer);
                }
            });
            // this.init();
        },
    },
    computed: {
        scrollWheelZoom: {
            get: function () {
                if (this.map === null) {
                    return this.mapOptions.scrollWheelZoom;
                }
                return this.map.scrollWheelZoom._enabled === undefined ? false : this.map.scrollWheelZoom._enabled;
            },
            set: function (newValue) {
                if (this.map.scrollWheelZoom._enabled) {
                    this.map.scrollWheelZoom.disable();
                    return;
                }
                this.map.scrollWheelZoom.enable();
            }
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
